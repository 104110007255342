<template>
    <div class="cleaning-stage-2-wrapper text-center">
        <div class="stage-2-title"> {{ $t('cleaning-stage-2-title') }}</div>
        <div class="stage-2-subtitle">{{ $t('cleaning-stage-2-subtitle') }}</div>

        <div class="stage-2-one-time-wrapper" v-if="selectedServiceType === 'one-time' && selectedServices">
            <div v-for="(item, index) in selectedServices" :key="index"
                 class="d-flex align-items-center selected-service-wrapper">
                <div class="me-auto">{{ $t(item.serviceKey) }}</div>
                <div class="ms-auto d-flex">
                  <div v-if="item.inputType === 'plus-minus'" class="ms-auto d-flex align-items-center">
                    <PlusMinusInput v-model="item.quantity"/>
                    <div class=" input-info"> {{ $t('piece') }}</div>
                  </div>
                  <div v-if="item.inputType === 'number'" class="ms-auto d-flex align-items-center">
                    <InputField v-model="item.quantity" :content="item.quantity" :size="'small'" :theme="'border-rectangle'"/>
                    <div class=" input-info"> {{ $t('square-meter') }}</div>
                  </div>
                  <div class="ms-3">
                    <Dropdown size="small" :label="$t('dirtiness')" v-model="item.severity" :options="severityOptions"/>
                  </div>
                </div>
            </div>
        </div>

        <div class="stage-2-subscription-cleaning-wrapper mt-5" v-if="selectedServiceType === 'subscription'">
            <div class="stage-2-subscription-title text-center font-bold">
                {{ $t('service') }}: {{ selectedSubscriptionService }}
            </div>
            <div class="d-flex justify-content-center align-items-center selected-service-wrapper">
                <div class="me-auto font-bold">{{ $t('enter-room-size') }}:</div>
                <div class="d-flex align-items-center">
                    <InputField :size="'small'" :theme="'border-rectangle'" inputtype="number" v-model="subscriptionSqmForm" :content="subscriptionSqmForm"/>
                    <div class=" input-info"> {{ $t('square-meter') }}</div>
                </div>
            </div>
        </div>

        <div class="stage-2-subscription-cleaning-wrapper mt-5" v-if="selectedServiceType === 'moving'">
            <div class="d-flex justify-content-center align-items-center selected-service-wrapper">
                <div class="me-auto font-bold">{{ $t('moving-enter-1-worker-hours') }}:</div>
                <div class="d-flex align-items-center">
                    <InputField :size="'small'" :theme="'border-rectangle'" inputtype="number" v-model="movingWorkers.one" :content="movingWorkers.one"/>
                    <div class=" input-info"> {{ $t('hours') }}</div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center selected-service-wrapper">
                <div class="me-auto font-bold">{{ $t('moving-enter-2-worker-hours') }}:</div>
                <div class="d-flex align-items-center">
                    <InputField :size="'small'" :theme="'border-rectangle'" inputtype="number" v-model="movingWorkers.two" :content="movingWorkers.two"/>
                    <div class=" input-info"> {{ $t('hours') }}</div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center selected-service-wrapper">
                <div class="me-auto font-bold">{{ $t('moving-enter-3-worker-hours') }}:</div>
                <div class="d-flex align-items-center">
                    <InputField :size="'small'" :theme="'border-rectangle'" inputtype="number" v-model="movingWorkers.three" :content="movingWorkers.three"/>
                    <div class=" input-info"> {{ $t('hours') }}</div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center selected-service-wrapper">
                <div class="me-auto font-bold">{{ $t('moving-enter-4-worker-hours') }}:</div>
                <div class="d-flex align-items-center">
                    <InputField :size="'small'" :theme="'border-rectangle'" inputtype="number" v-model="movingWorkers.four" :content="movingWorkers.four"/>
                    <div class=" input-info"> {{ $t('hours') }}</div>
                </div>
            </div>
        </div>

        <div class="stage-2-subscription-cleaning-wrapper mt-5" v-if="selectedServiceType === 'transport'">
            <div class="d-flex justify-content-center align-items-center selected-service-wrapper">
                <div class="me-auto font-bold">{{ $t('moving-transport-enter-hours') }}:</div>
                <div class="d-flex align-items-center">
                    <InputField :size="'small'" :theme="'border-rectangle'" inputtype="number" v-model="movingTransportHours" :content="movingTransportHours"/>
                    <div class=" input-info"> {{ $t('hours') }}</div>
                </div>
            </div>
        </div>

        <div class="stage-2-subscription-cleaning-wrapper mt-5" v-if="selectedServiceType === 'helper'">
            <div class="d-flex justify-content-center align-items-center selected-service-wrapper">
                <div class="me-auto font-bold">{{ $t('moving-helper-enter-hours') }}:</div>
                <div class="d-flex align-items-center">
                    <InputField :size="'small'" :theme="'border-rectangle'" inputtype="number" v-model="movingHelperHours" :content="movingHelperHours"/>
                    <div class=" input-info"> {{ $t('hours') }}</div>
                </div>
            </div>
        </div>

        <div class="mt-5" v-if="selectedServiceType === 'deep'">
            <CustomerInfoForm v-model="clientInfoForm"/>
        </div>
    </div>
</template>

<script>
import PlusMinusInput from "@/components/Common/Inputs/PlusMinusInput/PlusMinusInput";
import InputField from "@/components/Common/Inputs/Input/Input";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import CustomerInfoForm from "@/views/SelectCleaningServicePage/CustomerInfoForm.vue";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";

export default {
    name: "SelectCleaningStage2",
    props: {
        selectedServiceTitle: {
            type: String,
            default: ''
        },
        inputType: {
            type: String,
            default: ''
        },
        selectedServiceType: {
            type: String,
            default: ''
        },
        clientInfoForm: {
            type: String,
            default: ''
        },
    },
    components: {
      Dropdown,
        CustomerInfoForm,
        PlusMinusInput,
        InputField,
        TextArea
    },
    watch: {
        clientInfoForm(newValue, oldValue) {
            this.$emit('update:modelValue', newValue);
        },
        subscriptionSqmForm(newValue, oldValue) {
            this.$emit('update:modelValue', newValue);
        },
        movingTransportHours(newValue, oldValue) {
            this.$emit('update:modelValue', newValue);
        },
        movingHelperHours(newValue, oldValue) {
            this.$emit('update:modelValue', newValue);
        },
        movingWorkers: {
            handler(newVal, oldVal){
                this.$emit('update:modelValue', {
                    form: newVal,
                    isValid: this.isMovingWorkersValid()
                });
            },
            deep: true,
            immediate: true
        },
    },
    data() {
        return {
            selectedSubscriptionService: 'Стандарт',
            selectedServices: null,
            clientInfoForm: {},
            subscriptionSqmForm: null,
            movingWorkers: {},
            movingTransportHours: null,
            movingHelperHours: null,
            severityOptions: {}
        }
    },
    mounted() {
        this.severityOptions[this.$t('normal')] = 1;
        this.severityOptions[this.$t('medium')] = 2;
        this.severityOptions[this.$t('high')] = 3;

        this.selectedServices = this.$store.state.cleaning.selectedServices;
    },
    methods: {
        isMovingWorkersValid() {
            if (Object.keys(this.movingWorkers) === 0) {
                return false;
            }
            let total = 0;
            if (this.movingWorkers.hasOwnProperty('one')) {
                total += this.movingWorkers.one;
            }
            if (this.movingWorkers.hasOwnProperty('two')) {
                total += this.movingWorkers.two;
            }
            if (this.movingWorkers.hasOwnProperty('three')) {
                total += this.movingWorkers.three;
            }
            if (this.movingWorkers.hasOwnProperty('four')) {
                total += this.movingWorkers.four;
            }
            return total > 0;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.font-bold {
    font-family: $font-bold;
}

.cleaning-stage-2-wrapper {
    padding: $spacing-3;
}

.stage-2-title {
    @include subtitle-3;
    padding-bottom: $spacing-1;
}

.stage-2-subtitle {
    margin-bottom: $spacing-8;
    @include paragraph-1;
}

.selected-service-wrapper {
    padding: $spacing-3 20px $spacing-3 0;
    border-bottom: 1px solid $color-grey-200;
}

.stage-2-one-time-wrapper, {
    border-top: 1px solid $color-grey-200;
}

.stage-2-subscription-title {
    padding-bottom: $spacing-3;
    border-bottom: 1px solid $color-grey-200;
}

.input-info {
    margin-left: 10px;
}

</style>
